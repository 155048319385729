import React from 'react'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link } from 'gatsby';
import { BiChevronRightCircle } from "react-icons/bi";
import './index.css';



function index({ id, img, title, type, description, slug, bgImg }) {

    // console.log(bgImg);

    return (
        <Link to={`/career/${slug}/`}>
            <div className="careerCard group">
                <div
                    className='hoverBg'
                    style={{
                        backgroundImage: `url(${bgImg})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        opacity: 0.2
                    }}
                />
                {
                    img && (
                        <img 
                            src={img} 
                            width={57}
                            height={49}
                            alt={title}
                            title={title}
                        />
                    )
                }
                {
                    bgImg && (
                        <img 
                            src={bgImg} 
                            width={57}
                            height={49}
                            className="hoverImg"
                            alt={title}
                            title={title}
                        />
                    )
                }
                <h3 className='careerCard__title'>{title}</h3>
                <div className='careerCard__divider' />

                
                <p className='careerCard__desc'>
                    <span>
                        <BsChevronLeft/>
                            {type}
                        <BsChevronRight/>
                    </span>
                    {description}
                </p>

                <Link to={`/career/${slug}/`} className='careerCard__cta'>
                    Read More
                    <BiChevronRightCircle className='chev_icon'/>
                </Link>

            </div>
        </Link>
    )
}

export default index