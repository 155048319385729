import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Meta from "../../components/meta"
import DummyHero from "../../components/dummy-hero"
import { StaticImage } from "gatsby-plugin-image"
import logo from "../../images/favicon.png"
import "./index.css"
import CareerGrid from "../../components/career-grid"

function Careers({ data }) {
  console.log({ data })

  const getFullTime = () => {
    const list = data.allStrapiCareer.nodes
    const filtedList = list.filter(e => e.type.toLowerCase().includes("full"))
    filtedList.sort((a, b) => a.title.localeCompare(b.title))
    return filtedList ?? []
  }

  const getPartTime = () => {
    const list = data.allStrapiCareer.nodes
    const filtedList = list.filter(e => e.type.toLowerCase().includes("part"))
    filtedList.sort((a, b) => a.title.localeCompare(b.title))
    return filtedList ?? []
  }

  return (
    <>
      <Layout>
        <DummyHero
          title="Join our Team"
          description={`Looking to be part of a global technology company? `}
          secDescription={`You are at the right place! Check out our opening positions.`}
        />
        <div className="careerContainer">
          <div className="careerContainer__bgright">
            <StaticImage
              src="../../images/dllbg_1.png"
              width={400}
              height={400}
              quality={100}
              placeholder="blurred"
              objectFit="cover"
              alt="DigitalLaboratory Career Background Image"
              title="DigitalLaboratory Career Background Image"
            />
          </div>

          <div className="careerWrapper">
            {/* Full Time Cards */}

            <CareerGrid title={"Full Time"} items={getFullTime()} />
            {/* {JSON.stringify(getFullTime())} */}
          </div>

          <div className="careerContainer__bgleft">
            <StaticImage
              src="../../images/dllbg_2.png"
              className=""
              width={400}
              height={400}
              quality={100}
              placeholder="blurred"
              objectFit="cover"
              alt="DigitalLaboratory Career Background Image"
              title="DigitalLaboratory Career Background Image"
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Careers

export const Head = () => {
  // console.log("blogs: ", blogs);

  let meta = {
    title: "Career",
    description:
      "CAREERS Join our team Looking to be part of a global technology company? You are at the right place! Check out our opening positions. See All Positions COVID-19 update: We care for the well-being of both you and our people. Hence, interviews will be conducted virtually until restrictions change. Sales Administrator Communicate with clients and [&hellip;]",
    url: "https://www.digitallaboratory.net/career/",
    image: logo,
  }

  return <Meta meta={meta} />
}

export const query = graphql`
  {
    allStrapiCareer {
      nodes {
        id
        title
        slug
        type
        card_description
        image {
          alternativeText
          url
        }
        open_to {
          data {
            open_to
          }
        }

        working_day {
          data {
            working_day
          }
        }
        working_hour {
          data {
            working_hour
          }
        }
        seo {
          title
          description
        }
        number_of_hiring
        job_description {
          data {
            job_description
          }
        }
        job_requirement {
          data {
            job_requirement
          }
        }
        offer {
          data {
            offer
          }
        }
        image_hover {
          url
          alternativeText
        }
      }
    }
  }
`
