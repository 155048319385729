import React from "react"
import CareerCard from "../career-card"

const CareerGrid = ({ title, items }) => {
  console.log({ items })
  if (!items) return <></>
  return (
    <>
      <div className="containerWrapper">
        <h2 className="mt-16 mb-10 text-center text-[#0D1B60] leading-[23px] font-[700] px-[25px] text-[28px] md:text-[32px]">{title}</h2>
        <div className="flex flex-wrap items-center justify-center gap-8 mb-16">
          {items?.map(node => {
            return (
              <CareerCard
                key={node.title}
                id={node.id}
                img={`https://strapi-admin.digitallaboratory.net${node.image.url}`}
                title={node.title}
                slug={node.slug}
                type={node.type}
                description={node.card_description}
                bgImg={`https://strapi-admin.digitallaboratory.net${node?.image_hover?.url}`}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default CareerGrid
