import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import './index.css';


function index({ title, subtitle, description, secDescription }) {
    return (
        <div className='dummyHeroBanner'>
            <div className="dummyHeroBanner__background-image">
                <StaticImage
                    src="../../images/DLL_mv2.png"
                    quality={100}
                    objectFit="cover"
                    placeholder="blurred"
                    alt={`DigitalLaboratory Myanmar ${title} Hero Image`}
                    title={`DigitalLaboratory Myanmar ${title} Hero Image`}
                />
            </div>
            <div className={"dummyHeroBanner__wrapper"}>
                <div className={`${subtitle ? 'items-start md:items-end' : 'items-center'} dummyHeroBanner__wrapper__content`}>
                    <h1 className={`${description ? 'pb-[19px]' : ''}`}>{title}</h1>

                    {
                        description && (
                            <h2 className='dummyHeroBanner__wrapper__desc'>
                                {description}
                            </h2>
                        )
                    }

                    {
                        secDescription && (
                            <h2 className='dummyHeroBanner__wrapper__desc'>
                                {secDescription}
                            </h2>
                        )
                    }

                    {
                        subtitle && (
                            <p>{subtitle}</p>
                        )
                    }
                </div>


            </div>
        </div>
    )
}

export default index